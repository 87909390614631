// JavaScript Document

// Scripts written by Jordan @ Weblinx, Inc.

import { library, dom } from "@fortawesome/fontawesome-svg-core";

import { faStar as fasFaStar } from "@fortawesome/pro-solid-svg-icons/faStar";
import { faCalendar as fasFaCalendar } from "@fortawesome/pro-solid-svg-icons/faCalendar";
import { faAngleRight as fasFaAngleRight } from "@fortawesome/pro-solid-svg-icons/faAngleRight";
import { faPlus as fasFaPlus } from "@fortawesome/pro-solid-svg-icons/faPlus";
import { faTimes as fasFaTimes } from "@fortawesome/pro-solid-svg-icons/faTimes";

/**
 * Add solid icons
 */
library.add(fasFaStar, fasFaCalendar, fasFaAngleRight, fasFaPlus, fasFaTimes);

/**
 * Watch the DOM to insert icons
 */
dom.watch();
